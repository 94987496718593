<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine">
      <div class="common-plate-title">
        <span>
          <span class="iconfont icon-youhuiquan"></span>
          <span>{{ $trans('优惠券', '优惠券') }}</span>
        </span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div class="flex">
        <div class="aside-box flex-column-start">
          <div
            @click="toDiscount"
            class="tab-item font-regular"
            :class="tabActiveIndex == 5 ? 'active' : ''"
          >
            {{ $trans('兑换', '兑换') }}
          </div>
          <div
            class="tab-item font-regular"
            v-for="(item, index) in tabList"
            :key="item.type"
            :data-link="item.link"
            @click="changeTabBar($event)"
            :class="tabActiveIndex == index ? 'active' : ''"
          >
            {{ $trans(item.text, item.text) }}
          </div>
        </div>
        <div class="main-box">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      tabList: [
        {
          text: '未使用',
          link: '/discount/index',
        },
        {
          text: '已使用',
          link: '/discount/notutilize',
        },
        {
          text: '已失效',
          link: '/discount/disabled',
        },
      ],
    }
  },
  methods: {
    changeTabBar(e) {
      this.$router.history.push({ path: e.target.dataset.link })
    },
    toDiscount() {
      this.$router.history.push('/discount/convert')
    },
  },
  computed: {
    tabActiveIndex() {
      if (this.$route.path.indexOf('index') > -1) {
        return 0
      } else if (this.$route.path.indexOf('notutilize') > -1) {
        return 1
      } else if (this.$route.path.indexOf('disabled') > -1) {
        return 2
      } else {
        return 5
      }
    },
  },
}
</script>
<style scoped>
.common-plate-title {
  margin-bottom: 100px;
}
.tab-item {
  color: #666666;
  font-size: 16px;
  margin-bottom: 39px;
  cursor: pointer;
}
.tab-item.active {
  color: #111111;
  font-weight: bold;
}
.main-box {
  flex: 1;
}
</style>
